import React, {useEffect, useState, useRef, useCallback} from 'react';
import ViewMenuGamesList from 'app/components/pages/home_page/ViewMenuGames/ViewMenuGamesList';
import ViewMenuGamesItem from 'app/components/pages/home_page/ViewMenuGames/ViewMenuGamesItem';
import {inject, observer} from 'mobx-react';
import {getWidth} from 'app/utils/getWidth';
import {isNewDesktop} from 'app/utils/windowFunctions';
import {SwiperSlide} from 'swiper/swiper-react';
import {sortByFilters} from 'app/utils/SortByFilters';
import {OTHER} from 'app/store/GamesStore';

const STUBS_COUNT = 9;

const ViewMenuGames = inject('tableStore')(observer(({tableStore}) => {
    const getFilters = () => {
        const tableFilters = tableStore.allLines.map(b => b.filter);
        const {filters} = tableStore.filterStore;
        return sortByFilters(filters.filter(filter => tableFilters.includes(filter.id) && filter.id !== OTHER));
    };

    const filters = getFilters();

    const [menuGameLength, setMenuGameLength] = useState(getFilters().length);
    const [renderSlider, setRenderSlider] = useState(true);

    const rootElement = useRef(null);
    const viewMenuSlideElement = useRef(null);


    const setRenderSwiper = useCallback(() => {
        const menuList = getWidth('.view-menu-games-without-swiper') || getWidth('.view-menu-games-swiper .swiper-wrapper');

        if (viewMenuSlideElement.current && menuList) {
            const viewMenu = viewMenuSlideElement.current.clientWidth;
            setRenderSlider(isNewDesktop() && viewMenu < menuList);
        }
    }, []);

    useEffect(() => {
        setRenderSwiper();
        window.addEventListener('resize', setRenderSwiper);

        return () => {
            window.removeEventListener('resize', setRenderSwiper);
        };
    }, [setRenderSwiper]);

    useEffect(() => {
        const currentFiltersLength = getFilters().length;

        if (currentFiltersLength !== menuGameLength) {
            setMenuGameLength(currentFiltersLength);
            setRenderSwiper();
        }
    }, [
        menuGameLength,
        tableStore,
        setRenderSwiper
    ]);

    const renderGameItems = count => <div className="view-menu-games-loading">
        {Array.from({length: count}, (_, index) => <span key={index} className="view-game-item" />)}
    </div>;

    return (
        <div className="view-menu-games">
            <div className="view-menu-games-slide" ref={viewMenuSlideElement}>
                {filters && filters.length === 0
                    ? <>{renderGameItems(STUBS_COUNT)}</>
                    : <div
                        className={`view-menu-games-group${renderSlider ? '' : ' without-swiper'}`}
                        ref={rootElement}
                    >
                        <ViewMenuGamesList isSwiper={renderSlider}>
                            {filters.map(filter => <SwiperSlide key={`filter-${filter.id}`}>
                                <ViewMenuGamesItem {...filter}
                                    filter_image={filter.table_image}
                                    tableStore={tableStore} />
                            </SwiperSlide>)}
                        </ViewMenuGamesList>
                    </div>
                }
            </div>
        </div>
    );
}));

export default ViewMenuGames;
