import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import I18n from 'app/i18n';
import {useNavigate} from 'react-router-dom';
import {startCasinoGame} from 'app/services/CasinoServices';
import {getTableLinkPath} from 'app/utils/link_helper';
import {modalConfirm} from 'app/utils/modals/popups';
import EmptyOffers from 'app/components/my_profile/trades/empty_offers.jsx';
import {UserBonusesStore} from 'app/store/UserBonusesStore';
import FreespinInterface from 'app/interfaces/Freespinsinterface';

const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const MILLISECONDS_IN_MINUTE = 60000;
const RADIX = 10;

interface FreespinsContentItemProps {
  userBonusesStore?: UserBonusesStore;
}

const FreespinsContentItem: React.FC<FreespinsContentItemProps> = inject('userBonusesStore')(
    observer(({userBonusesStore}) => {
        const navigate = useNavigate();
        const [spins, setSpins] = useState<FreespinInterface[]>(userBonusesStore?.free_spins || []);

        useEffect(() => {
            setSpins(userBonusesStore?.free_spins);
        }, [userBonusesStore?.free_spins]);

        useEffect(() => {
            const intervalId = setInterval(() => {
                setSpins(currentSpins => currentSpins
                    .map(spin => {
                        const {days, hours, minutes} = spin.time;
                        const totalMinutes = parseInt(days, RADIX) * HOURS_IN_DAY * MINUTES_IN_HOUR +
                                parseInt(hours, RADIX) * MINUTES_IN_HOUR +
                                parseInt(minutes, RADIX);

                        if (totalMinutes <= 0) {
                            return null;
                        }

                        const newTotalMinutes = totalMinutes - 1;
                        const newDays = Math.floor(newTotalMinutes / (HOURS_IN_DAY * MINUTES_IN_HOUR));
                        const newHours = Math.floor(newTotalMinutes % (HOURS_IN_DAY * MINUTES_IN_HOUR) / MINUTES_IN_HOUR);
                        const newMinutes = newTotalMinutes % MINUTES_IN_HOUR;

                        return {
                            ...spin,
                            time: {
                                days: String(newDays),
                                hours: String(newHours),
                                minutes: String(newMinutes)
                            }
                        };
                    })
                    .filter(spin => spin !== null));
            }, MILLISECONDS_IN_MINUTE);

            return () => clearInterval(intervalId);
        }, [spins]);

        const handleActivateClick = (game_id: string) => {
            navigate(getTableLinkPath('/casino'));
            startCasinoGame(Number(game_id));
        };

        const handleCancelClick = (spin: FreespinInterface) => {
            modalConfirm(I18n.t('casino_free_spins.confirm_deactivate', {count: spin.quantity, name: spin.game}), () => {
                userBonusesStore.cancelFreeSpin(spin.id);
            });
        };

        if (spins.length === 0) {
            return (
                <div className="exchange">
                    <EmptyOffers subtitle={I18n.t('freespins_have_no')} description={I18n.t('freespins_have_no')} />
                </div>
            );
        }

        return (
            <>
                {spins.map(spin => <div key={spin.id} className="freespins-item">
                    <div className="freespins-item__about">
                        <div className="freespins-provider">
                            <span className="freespins-item-title">{I18n.t('provider')}</span>
                            <span className="freespins-item-name" title={spin.provider}>
                                {spin.provider}
                            </span>
                        </div>
                        <span className="freespins-count">{spin.quantity}</span>
                    </div>
                    <div className="freespins-game">
                        <span className="freespins-item-title">{I18n.t('freespins_slot')}</span>
                        <span className="freespins-item-name" title={spin.game}>
                            {spin.game}
                        </span>
                    </div>
                    <div className="freespins-timer">
                        <span className="freespins-timer-title">{I18n.t('expiring_in')}</span>
                        <span className="freespins-timer-count">
                            {I18n.t('casino_free_spins.duration', {
                                days: spin.time.days,
                                hours: spin.time.hours,
                                minutes: spin.time.minutes
                            })}
                        </span>
                    </div>
                    <div className="freespins-buttons">
                        <button
                            className="button-adaptive button-adaptive_uppercase"
                            onClick={() => handleActivateClick(spin.game_id)}
                        >
                            {I18n.t('go_game')}
                        </button>
                        <button
                            className="button-adaptive button-adaptive_crystal"
                            onClick={() => handleCancelClick(spin)}
                        >
                            {I18n.t('casino_free_spins.cancel')}
                        </button>
                    </div>
                </div>)}
            </>
        );
    })
);

export default FreespinsContentItem;
