import {inject} from 'mobx-react';
import React, {useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router';
import {useLocation, useNavigate} from 'react-router-dom';
import {getLinkPath, isDesktop, isDesktopMedia, isGuest} from 'app/utils';
import AppPageWrapper from '../layouts/page_wrapper';
import Chat from '../Chat';
import HiddenTemple from '../hidden_temple';
import About from 'app/components/pages/about';
import FaqIndex from 'app/components/pages/faq/FaqIndex';
import Messages from 'app/components/pages/messages';
import HomePage from 'app/components/pages/home_page';
import PlayLiveContent from 'app/components/pages/play_content/live';
import PlaySimpleBetsContent from 'app/components/pages/play_content/simple_bets';
import Casino from 'app/components/pages/casino/Casino';
import SiteVersion from 'site_version/SiteVersion';
import PlayPageWrapper from '../pages/PlayPageWrapper';
import ArticleIndex from 'app/components/pages/articles/ArticleIndex';
import Articles from 'app/components/pages/articles/Articles';
import PromoNewPage from 'app/components/pages/articles/PromoNewPage';
import ResultsEsportContent from '../pages/play_content/ResultsEsportContent';
import {replaceLocale} from 'app/utils/langs/replaceLocale';
import AppsPage from 'app/components/pages/apps/apps_page';
import AndroidManual from 'app/components/pages/apps/android_manual';
import CashoutPage from 'app/components/cashout/cashout_page';
import StaticPage from 'app/components/pages/static_page';
import DepositPage from 'app/components/deposit/DepositPage';
import Profile from 'app/components/pages/profile';
import Contacts from 'app/components/pages/contacts';
import TradeOffer from 'app/components/pages/trade_offer';
import MyBets from 'app/components/my_bets';
import Settings from 'app/components/my_profile/Settings';
import MyProfileTabs from 'app/components/my_profile/MyProfileTabs';
import MyBonusesPage from 'app/components/my_profile/MyBonusesPage';
import MyFreeSpinsPage from 'app/components/my_profile/MyFreeSpinsPage';
import Prizes from 'app/components/my_profile/prizes';
import Hero from 'app/components/my_profile/hero';
import NotificationSettings from 'app/components/my_profile/notifications';
import {UserMenu} from 'app/components/user/menu';
import Trades from 'app/components/my_profile/trades';
import {MyHistoryTrades, MyReceivedTrades, MySentTrades} from 'app/components/my_profile/trades/my_trades';
import MyProfile from 'app/components/my_profile';
import {SuccessfulRegistration} from 'app/components/pages/timer_page';
import Esport from 'app/components/pages/esport/esport';
import EsportShow from 'app/components/pages/esport/EsportShow';
import ShowESportBet from 'app/components/pages/esport/ShowESportBet';
import LandingPageContainer from 'app/components/promo_action/LandingPageContainer';
import Recipes from 'app/components/shop/marketplace/recipes';
import Orders from 'app/components/shop/marketplace/orders';
import LootboxesContainer from 'app/components/shop/LootboxesContainer';
import LootboxDetails from 'app/components/shop/lootboxes/LootboxDetails';
import CompendiumRedeemPrize from 'app/components/compendium/redeem_prize';
import Ti8 from 'app/components/compendium/predictions';
import BlogIndex from 'app/components/blog';
import BlogShow from 'app/components/blog/show';
import Shop from 'app/components/pages/Shop';
import MoneyItems from 'app/components/shop/MoneyItems';
import ProductItems from 'app/components/shop/ProductItems';
import MyPurchases from 'app/components/shop/MyPurchases';
import Referrals from 'app/components/pages/Referrals';
import AdvertisingLanding from 'app/components/pages/AdvertisingLanding';
import IncubatorContent from 'app/components/pages/play_content/IncubatorContent';
import Incubator from 'app/components/pages/incubator/Incubator';
import IncubatorLineForm from 'app/components/pages/incubator/IncubatorLineForm';
import LandingLadderGame from 'app/components/landing_ladder_game/LandingLadderGame';
import WelcomeLanding from 'app/components/landing_promo/WelcomeLanding';
import Sport from 'app/components/pages/sport/Sport';
import {ScreenStore} from 'app/store/ScreenStore';
import {initializeNavigate} from 'app/utils/navigation';
import ResultsSportContent from 'app/components/pages/play_content/ResultsSportContent';
import IncubatorSportContent from 'app/components/pages/play_content/IncubatorSportContent';
import PlaySportLiveContent from 'app/components/pages/play_content/sport_live';
import PlaySimpleSportBetsContent from 'app/components/pages/play_content/simple_sport_bets';
import runCallbacks from 'app/callbacks';
import ModalsContainer from 'app/utils/modals/ModalsContainer';
import {GroupToastContainer} from 'app/components/toasts/GroupToastContainer';
import OtherItemsContainer from 'app/components/shop/OtherItemsContainer';
import OtherItemDetails from 'app/components/shop/lootboxes/OtherItemDetails';
import MainGrid from 'app/components/style_guide/MainGrid';
import GridPage1 from 'app/components/style_guide/GridPage1';
import GridPage2 from 'app/components/style_guide/GridPage2';

const ARTICLE_NEWS_TYPE = 'news';
const ARTICLE_PROMOTION_OLD_TYPE = 'promotions_old';
const ARTICLE_CAREERS_TYPE = 'careers';
const ARTICLE_HIDDEN_TEMPLE_TYPE = 'hidden_temples';

const DOTA_2_GAME = 4;
const CSGO_GAME = 2;
const HEARTHSTONE_GAME = 9;
const HOMM_GAME = 56;

interface MainRoutesInterfaceProps {
    screenStore?: ScreenStore
}

const localeScope = (): string => window.CURRENT_LOCALE === window.DEFAULT_LOCALE ? '/' : window.CURRENT_LOCALE;

// eslint-disable-next-line max-lines-per-function,max-statements
const MainRoutes = (props: MainRoutesInterfaceProps): React.JSX.Element => {
    runCallbacks();

    const {screenStore} = props;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        initializeNavigate(navigate);
        const handleResize = () => {
            setTimeout(() => {
                screenStore.setDesktop(isDesktopMedia());
            }, 100);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [screenStore]);

    const chatFullScreen = (): JSX.Element => <Chat fullScreen/>;

    const chatMobile = (): JSX.Element => <Chat mobile/>;

    const csgoLanding = (): JSX.Element => <LandingLadderGame game_id={CSGO_GAME}/>;

    const dotaLanding = (): JSX.Element => <LandingLadderGame game_id={DOTA_2_GAME}/>;

    const hearthstoneLanding = (): JSX.Element => <LandingLadderGame game_id={HEARTHSTONE_GAME}/>;

    const hommLanding = (): JSX.Element => <LandingLadderGame game_id={HOMM_GAME}/>;

    const welcomeLanding = (): JSX.Element => <WelcomeLanding/>;

    const news = (): JSX.Element => <Articles type={ARTICLE_NEWS_TYPE}/>;

    const hiddenTemples = (): JSX.Element => <Articles type={ARTICLE_HIDDEN_TEMPLE_TYPE}/>;

    const promotions = (): JSX.Element => <PromoNewPage/>;

    const promotionsOld = (): JSX.Element => <Articles type={ARTICLE_PROMOTION_OLD_TYPE}/>;

    const careers = (): JSX.Element => <Articles type={ARTICLE_CAREERS_TYPE}/>;

    const newsIndex = (): JSX.Element => <ArticleIndex type={ARTICLE_NEWS_TYPE}/>;

    const promotionsIndex = (): JSX.Element => <ArticleIndex type={ARTICLE_PROMOTION_OLD_TYPE}/>;

    const careersIndex = (): JSX.Element => <ArticleIndex type={ARTICLE_CAREERS_TYPE}/>;

    /*
     * Static getDerivedStateFromError(): void {
     *     return goToRoute('/play/simple_bets');
     * }
     */

    return <>
        <ModalsContainer/>
        <GroupToastContainer/>
        <Routes>
            <Route path={localeScope()} element={<AppPageWrapper />}>
                <Route index element={<HomePage/>} />
                <Route path="news" element={news()}/>
                <Route path="news/hidden_temples" element={hiddenTemples()}/>
                <Route path="news/:id" element={newsIndex()}/>
                <Route path="promotions" element={promotions()}/>
                <Route path="promotions_old" element={promotionsOld()}/>
                <Route path="promotions_old/:id" element={promotionsIndex()}/>
                <Route path="promotions/:id" element={promotionsIndex()}/>
                <Route path="careers" element={careers()}/>
                <Route path="careers/:id" element={careersIndex()}/>
                <Route path="chat/mobile" element={chatMobile()}/>
                <Route path="about" element={<About/>}/>
                <Route path="faq" element={<FaqIndex />}>
                    <Route path=":id" element={<FaqIndex />} />
                </Route>
                <Route path="play" element={<PlayPageWrapper />}>
                    <Route index element={<Navigate to="bets" />} />
                    <Route path="express" element={<Navigate to="/play/bets" replace />} />
                    <Route path="bets">
                        <Route index element={<PlaySimpleBetsContent />} />
                        <Route path=":filter" element={<PlaySimpleBetsContent />} />
                    </Route>
                    <Route path="simple_sport_bets">
                        <Route index element={<PlaySimpleSportBetsContent />} />
                        <Route path=":filter" element={<PlaySimpleSportBetsContent />} />
                    </Route>
                    <Route path="live">
                        <Route index element={<PlayLiveContent />} />
                        <Route path=":filter" element={<PlayLiveContent />} />
                    </Route>
                    <Route path="sport_live">
                        <Route index element={<PlaySportLiveContent />} />
                        <Route path=":filter" element={<PlaySportLiveContent />} />
                    </Route>
                    <Route path="incubator">
                        <Route index element={<Navigate to="all" />} />
                        <Route path="all" element={<IncubatorContent />} />
                        <Route element={<Incubator />}>
                            <Route path="new" element={<IncubatorLineForm />} />
                            <Route path="my_lines/:id" element={<IncubatorLineForm />} />
                            <Route path=":id" element={<IncubatorLineForm />} />
                        </Route>
                        <Route path=":filter" element={<PlaySimpleBetsContent />} />
                    </Route>
                    <Route path="sport_incubator">
                        <Route index element={<Navigate to="all" />} />
                        <Route path="all" element={<IncubatorSportContent />} />
                        <Route element={<Incubator />}>
                            <Route path="new" element={<IncubatorLineForm />} />
                            <Route path="my_lines/:id" element={<IncubatorLineForm />} />
                            <Route path=":id" element={<IncubatorLineForm />} />
                        </Route>
                        <Route path=":filter" element={<PlaySimpleBetsContent />} />
                    </Route>
                    <Route path="results">
                        <Route index element={<ResultsEsportContent />} />
                        <Route path=":filter" element={<ResultsEsportContent />} />
                    </Route>
                    <Route path="results_sport">
                        <Route index element={<ResultsSportContent />} />
                        <Route path=":filter" element={<ResultsSportContent />} />
                    </Route>
                </Route>
                <Route path="messages" element={<Messages/>}/>
                <Route path="hidden_temple" element={<HiddenTemple/>}/>
                <Route path="profile/:name" element={<Profile/>}/>
                <Route path="trade/:token" element={<TradeOffer/>}/>
                <Route path="profile/:name/trade" element={<TradeOffer/>}/>
                <Route path="contacts" element={<Contacts/>}/>
                <Route path="sport" element={<Sport/>}/>
                <Route path="casino" element={<Casino/>}/>
                <Route path="deposits" element={<DepositPage/>}>
                    <Route path=":system_slug" element={<DepositPage/>}/>
                </Route>
                <Route path="cashouts" element={<CashoutPage/>}>
                    <Route path=":system_slug" element={<CashoutPage/>}/>
                </Route>
                <Route path="sitemap" element={<SiteVersion.Sitemap />} />
                <Route path="sitemap.html" element={<SiteVersion.Sitemap />} />
                <Route path="my_bets" element={<MyBets/>}/>
                <Route path="my_profile/mobile" element={<UserMenu/>}/>
                <Route path="my_profile" element={<MyProfileTabs/>}>
                    <Route index element={isDesktop() ? <Navigate to="settings" /> : <Navigate to="mobile" />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="bonuses" element={<MyBonusesPage />} />
                    <Route path="freespins" element={<MyFreeSpinsPage />} />
                    <Route path="prizes" element={<Prizes />} />
                    <Route path="hero" element={<Hero />}>
                        <Route path="item/:recipeId"/>
                        <Route path="skin/:steamTradeId"/>
                    </Route>
                    <Route
                        path="notifications_settings"
                        element={<NotificationSettings />} />
                    <Route path="mobile" element={<UserMenu />} />
                    <Route path="trades" element={<Trades />}>
                        <Route index element={<Navigate to="sent" />} />
                        <Route path="outgoing" element={<MySentTrades />} />
                        <Route path="incoming" element={<MyReceivedTrades />} />
                        <Route path="history" element={<MyHistoryTrades />} />
                    </Route>
                    <Route path="referrals" element={<MyProfile.Referrals />} />
                </Route>
                <Route path="public/:id" element={<StaticPage />} />
                <Route path="successful_registration" element={<SuccessfulRegistration />} />
                <Route path="esports">
                    <Route index element={<Esport />} />
                    <Route path="bets/:game/:id" element={<ShowESportBet />} />
                    <Route path=":id" element={<EsportShow />} />
                </Route>
                <Route path="apps">
                    <Route index element={<AppsPage />} />
                    <Route path="android" element={<AndroidManual />} />
                </Route>
                <Route path="shop" element={<Shop/>}>
                    <Route index element={<Navigate to="money" />} />
                    <Route path="money" element={<MoneyItems />} />
                    <Route path="lootboxes" element={<LootboxesContainer />} />
                    <Route path="lootboxes/:name" element={<LootboxDetails />} />
                    <Route path="marketplace" element={<Recipes />} />
                    <Route path="marketplace/:name/orders" element={<Orders />} />
                    <Route path="items" element={<ProductItems />} />
                    <Route path="other" element={<OtherItemsContainer />} />
                    <Route path="other/:id" element={<OtherItemDetails />} />
                    <Route path="my_purchases" element={<MyPurchases />} />
                </Route>
                <Route path="promo/:url">
                    <Route index element={<LandingPageContainer />} />
                    <Route path="redeem_prize" element={<CompendiumRedeemPrize />} />
                    <Route path="predictions" element={<Ti8.Predictions />} />
                    <Route path="my_predictions" element={<Ti8.MyPredictions />} />
                    <Route path="rating" element={<Ti8.Rating />} />
                </Route>
                <Route path="blog">
                    <Route index element={<BlogIndex />} />
                    <Route path=":id" element={<BlogShow />} />
                </Route>
                <Route path="redeem-prize" element={<CompendiumRedeemPrize />} />
                <Route path="grid" element={<MainGrid />}>
                    <Route index element={<Navigate to="1" />} />
                    <Route path="1" element={<GridPage1 />} />
                    <Route path="2" element={<GridPage2 />} />
                </Route>
            </Route>
            <Route
                path={window.DEFAULT_LOCALE}
                element={<Navigate to={getLinkPath('/')} replace />}
            />
            <Route
                path={`${window.DEFAULT_LOCALE}/*`}
                element={<Navigate to={replaceLocale(location.pathname, window.DEFAULT_LOCALE)} replace />}
            />
            <Route
                path="xx"
                element={<Navigate to={getLinkPath('/')} replace />} />
            <Route
                path="xx/*"
                element={<Navigate to={location.pathname.replace(/^\/xx/u, '')} replace />} />
            <Route path={localeScope()}>
                <Route path="chat" element={chatFullScreen()}/>
                <Route path="referrals" element={<Referrals/>}/>
                <Route path="matches" element={<AdvertisingLanding/>}/>
                <Route path="csgo_ladder_matches" element={csgoLanding()}/>
                <Route path="dota2_ladder_matches" element={dotaLanding()}/>
                <Route path="hearthstone_ladder_matches" element={hearthstoneLanding()}/>
                <Route path="homm3_ladder_matches" element={hommLanding()}/>
                <Route path="casino_egb" element={isGuest() ? welcomeLanding() : <Navigate to="/play/bets"/> }/>
            </Route>
            {/* <Route path="*" element={<NotFoundPage />} />*/}
        </Routes>
    </>;
};

export default inject('screenStore')(MainRoutes);
