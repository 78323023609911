import React, {Component} from 'react';
import LetsShow from './home_page/LetsShow/LetsShow';
import {isDesktop} from 'app/utils';
import MainPageDescription from 'app/components/MainPageDescription';
import ViewMenuGames from 'app/components/pages/home_page/ViewMenuGames/ViewMenuGames';
import PreviewGames from 'app/components/pages/home_page/PreviewGames/PreviewGames';

class HomePage extends Component {
    render() {
        return isDesktop() && <div className="home-page-wrapper">

            <LetsShow/>

            <PreviewGames/>

            <ViewMenuGames/>

            <MainPageDescription/>
        </div>;
    }
}

export default HomePage;
