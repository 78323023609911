import React, {PureComponent} from 'react';
import SwiperCore from 'swiper';
import {Swiper} from 'swiper/swiper-react';
import {Navigation, Pagination} from 'swiper/modules';

const swiperParams = {
    allowTouchMove: true,
    freeMode: {
        momentum: false,
        momentumBounce: false
    },
    observeParents: true,
    observer: true,
    slidesPerGroup: 2,
    speed: 400,
    touchRatio: 0.65,
    touchReleaseOnEdges: false,
    touchStartForcePreventDefault: true
};

interface PreviewGamesListProps {
    isSwiper: boolean
    onCheckReached?: (swiper: SwiperCore) => void
    children?: React.ReactNode
}

export default class PreviewGamesList extends PureComponent<PreviewGamesListProps> {
    render(): React.ReactNode {
        const {children, onCheckReached} = this.props;

        return this.props.isSwiper
            ? <>
                <Swiper className="preview-games-swiper" {...swiperParams}
                    slidesPerView="auto"
                    freeMode
                    preventInteractionOnTransition
                    preventClicksPropagation
                    resistance={false}
                    watchSlidesProgress
                    onTouchEnd={onCheckReached}
                    onAfterInit={onCheckReached}
                    modules={[Pagination, Navigation]}
                    pagination={{
                        clickable: true,
                        el: '.swiper-buttons-pagination'
                    }}
                    navigation={{
                        nextEl: '.swiper-button-slider-next',
                        prevEl: '.swiper-button-slider-prev'
                    }}>{children}</Swiper>
                <div className="swiper-control-buttons">
                    <button className="swiper-button-slider-prev">
                        <svg className="svg-default">
                            <use xlinkHref="#svg-swiper-arrow"/>
                        </svg>
                    </button>
                    <div className="swiper-buttons-pagination"/>
                    <button className="swiper-button-slider-next">
                        <svg className="svg-default">
                            <use xlinkHref="#svg-swiper-arrow"/>
                        </svg>
                    </button>
                </div>
            </>
            : <div className="preview-games-without-swiper">{children}</div>;
    }
}
