import React, {PureComponent, ReactNode} from 'react';
import SmartPromotionItem from 'app/components/pages/home_page/SmartPromotion/SmartPromotionItem';
import {Swiper, SwiperSlide} from 'swiper/swiper-react';
import {Autoplay, EffectFade, Pagination} from 'swiper/modules';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';

const swiperParams = {
    allowTouchMove: true
};

interface ArticlesWithTypeProps {
    data: ArticleInterface[]
    type?: string
}

export default class SmartPromotionList extends PureComponent<ArticlesWithTypeProps> {
    render(): ReactNode {
        const {data, type} = this.props;
        return <div className="smart-promotion-list">
            <Swiper
                className="slider-smart-promotions"
                {...swiperParams}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                modules={[
                    Autoplay,
                    EffectFade,
                    Pagination
                ]}
                pagination={{clickable: true}}
                effect={'fade'}
                speed={700}
            >
                {data.map(article => <SwiperSlide key={article.id}>
                    <SmartPromotionItem data={article} type={type}/>
                </SwiperSlide>)}
            </Swiper>
        </div>;
    }
}
