import {BLUE, GREEN} from 'app/lines/line_helpers';
import I18n from 'app/i18n';
import LineInterface from 'app/interfaces/LineInterface';
import {TableStore} from 'app/store/TableStore';
import {getLinkPath} from 'app/utils/link_helper';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import PreviewGamesList from './PreviewGamesList';
import {SwiperSlide} from 'swiper/swiper-react';
import PreviewGamesItem from './PreviewGamesItem';
import {getWidth} from 'app/utils/getWidth';
import {isNewDesktop} from 'app/utils/windowFunctions';
import {currentStream} from 'app/utils/currentStream';
import GamerInterface from 'app/interfaces/GamerInterface';
import {Link} from 'react-router-dom';

interface PropsInterface {
    tableStore: TableStore
}

interface StateInterface {
    renderSlider: boolean
    menuGameLength: number
    loading: boolean
    viewMenu: number
}

const MAX_LINES_COUNT = 20;
const IMPORTANT_MATCH = 0;
const FINAL_MATCH = 1;
const STUBS_COUNT = 9;
const PADDING = 55;

@inject('tableStore')
@observer
export default class PreviewGames extends Component<PropsInterface, StateInterface> {
    rootElement: React.RefObject<HTMLDivElement> = React.createRef();

    viewMenuSlideElement: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: true,
            menuGameLength: 0,
            renderSlider: true,
            viewMenu: 0
        };
    }

    componentDidMount(): void {
        this.setState({loading: false, menuGameLength: this.filteredLines().length}, () => {
            this.setRenderSwiper();
        });
        window.addEventListener('resize', this.setRenderSwiper);
    }

    componentDidUpdate(): void {
        const lines = this.filteredLines();
        const {menuGameLength, viewMenu} = this.state;

        if (lines.length !== menuGameLength) {
            this.setState({menuGameLength: lines.length});
            this.setRenderSwiper();
        } else if (viewMenu === 0) {
            this.setRenderSwiper();
        }
    }

    setRenderSwiper = (): void => {
        const viewMenu = this.viewMenuSlideElement.current?.clientWidth || 0;
        const menuList = getWidth('.preview-games-without-swiper') || getWidth('.preview-games-swiper .swiper-wrapper');

        if (viewMenu !== 0) {
            this.setState({renderSlider: isNewDesktop() && viewMenu - PADDING < menuList, viewMenu});
        }
    };

    hasPhoto = (gamer: GamerInterface): number => gamer.photo.src === null ? 0 : 1;

    filteredLines(): LineInterface[] {
        const importantLines = [];

        this.props.tableStore.bets.slice().sort((a, b) => {
            const photoA = this.hasPhoto(a.gamer_1) + this.hasPhoto(a.gamer_2);
            const photoB = this.hasPhoto(b.gamer_1) + this.hasPhoto(b.gamer_2);

            if (photoA === photoB) {
                if (a.live === b.live) {
                    if (a.date === b.date) {
                        return a.id - b.id;
                    }
                    return a.date - b.date;
                }
                return b.live - a.live;
            }
            return photoB - photoA;
        }).forEach(line => {
            if ([BLUE, GREEN].includes(line.color)) {
                if (!line.incubator_2 && (line.label_type === IMPORTANT_MATCH || line.label_type === FINAL_MATCH)) {
                    importantLines.push(line);
                }
            }
        });

        if (importantLines.length >= MAX_LINES_COUNT) {
            return importantLines.slice(0, MAX_LINES_COUNT);
        }

        return importantLines;
    }

    renderGameItems = (count: number): React.ReactElement => <div className="preview-games-loading">
        {Array.from({length: count}, (_, index) => <span key={index} className="preview-game" />)}
    </div>;

    onClickToBets = (): void => {
        window.location.href = getLinkPath('/play');
    };

    render(): React.ReactNode {
        const lines = this.filteredLines();
        const {renderSlider, loading} = this.state;

        return !loading && lines.length > 0 && <div className="preview-games">
            <div className="preview-games-title">
                <h1 className="title-h1">{I18n.t('key_events')}</h1>
                <Link to={getLinkPath('/play/bets')} className="button-adaptive button-adaptive_bigger-light">
                    {I18n.t('to_bets')}
                </Link>
            </div>
            <div className="preview-games-slide" ref={this.viewMenuSlideElement}>
                {lines && lines.length === 0
                    ? <>{this.renderGameItems(STUBS_COUNT)}</>
                    : <div className={`preview-games-group${renderSlider ? '' : ' without-swiper'}`}
                        ref={this.rootElement}>
                        <PreviewGamesList isSwiper={renderSlider}>
                            {lines.map(line => <SwiperSlide key={`filter-${line.id}`}>
                                <PreviewGamesItem line={line} currentStream={currentStream(line)} updatedAt={line.ut}/>
                            </SwiperSlide>)}
                        </PreviewGamesList>
                    </div>}
                {!renderSlider && <div className="preview-games-slide-shadow"/>}
            </div>
        </div>;
    }
}
