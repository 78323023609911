import SiteVersion from '../SiteVersion';
import {userStore} from 'app/store/global';

SiteVersion.HeaderMenu = [
    {
        path: '/play/bets',
        section: 'bets'
    },
    ...userStore.user.experimental_features && [
        {
            path: '/sport',
            section: 'sport',
            svg: 'sport'
        }
    ],
    {
        path: '/casino',
        section: 'casino',
        svg: 'casino'
    },
    {
        path: '/faq',
        section: 'faq'
    }
];
