import React, {PureComponent} from 'react';
import SmartPromotionList from 'app/components/pages/home_page/SmartPromotion/SmartPromotionList';
import {inject, observer} from 'mobx-react';
import {ArticlesStore} from 'app/store/ArticlesStore';
import {newsArticles, promotionArticles} from 'app/utils';

interface LetsShowProps {
    articlesStore?: ArticlesStore;
}

@inject('articlesStore')
@observer
export default class LetsShow extends PureComponent<LetsShowProps> {
    constructor(props: LetsShowProps) {
        super(props);
        this.props.articlesStore.loadMainArticles();
    }

    render(): React.ReactNode {
        const {articlesStore} = this.props;
        return <div className="lets-show">
            <div className="lets-show__part">
                <SmartPromotionList data={newsArticles(articlesStore.homePageArticles)}
                    type="news"/>
            </div>
            <div className="lets-show__part">
                <SmartPromotionList data={promotionArticles(articlesStore.homePageArticles)}
                    type="promotions"/>
            </div>
        </div>;
    }
}
